import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

export const languages = {
    en: { nativeName: 'English', direction: 'ltr' },
    ru: { nativeName: 'Русский', direction: 'ltr' },
    ar: { nativeName: 'اللغة العربية', direction: 'rtl' }
}

// Генерируем версию на основе временной метки
const translationsVersion = Date.now()

export default i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: `/locales/{{lng}}/{{ns}}.json?version=${translationsVersion}`
        }
    })
